import tippy from 'tippy.js';
import './serivce-week-report.scss';
import * as moment from 'moment';
import { ServiceReportService } from 'services/service-report-service';
import { UserService } from 'services/user-service';
import { ServiceStationService } from 'services/service-station-service';
import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-dependency-injection';
import { Models } from '../../../models/core';
import { ServiceWeek } from 'models/service-report-models';
import { WeekReportDetailsDialog } from './details/week-report-details-dialog';
import { I18N } from 'aurelia-i18n';
import { Filters, filterTypes } from '../../../elements/Filter';

type ServiceWeeks = Map<string, Map<string, ServiceWeek & { show?: boolean }>>;
type ServiceStations = Map<string, ServiceWeeks>;

const WEEK_REPORT_DATE_VALUES = 'WEEK_REPORT_DATE_VALUES';

const GetCallbackData = <T>(cb: () => Promise<T>) => {
  return new Promise((res: (i: T) => unknown) => {
    setTimeout(async () => {
      res(await cb());
    });
  });
};

const displayType = {
  MONTH: 1,
  WEEK: 2,
} as const;

@autoinject
export class ServiceWeekReport {
  private year: number;
  private month: number;
  private week: number;

  protected countries: string[];
  serviceStations: Array<Models.ServiceStation>;

  // All reports
  reports: ServiceStations = new Map();

  monthWeeks: Map<string, number[]> = new Map();

  isLoading = false;

  forwardLocked = false;
  backwardLocked = false;
  type = 0;

  private startOnFirstWeekOfMonth = false;

  constructor(
    private serviceReportService: ServiceReportService,
    private userService: UserService,
    private serviceStationService: ServiceStationService,
    private dialogService: DialogService,
    private translator: I18N,
    private errorService: ErrorService
  ) {
    moment.locale('nb-NO');
  }

  protected context: string;
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<any>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    serviceStationsIds: true,
    month: true,
    year: true,
    type: true,
  };

  protected onFilterChanged() {
    void this.getWeekReport(true);
  }

  // All months
  months = new Array(12).fill(1).map((_, i) => ({ Id: i + 1, Name: moment.default().month(i).format('MMMM') }));
  // Available years
  years = [-2, -1, 0, 1].map((n) => {
    const y = moment.default().year() + n;
    return { Id: y, Name: y };
  });

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const user = await this.userService.getCurrentUser();

        const serviceStations = (await this.serviceStationService.getAll())?.sort((a, b) => (a.Name > b.Name ? 1 : -1)) || [];

        const filters: Filters = {
          serviceStationsIds: {
            name: 'serviceStationsIds',
            label: this.translator.tr('general.servicestation'),
            type: filterTypes.CHECKBOX,
            options: serviceStations,
            query: 'serviceStationsIds',
            defaultValues: user.ServiceStationId ? { [`${user.ServiceStationId}`]: true } : undefined,
            clear: (_, clearByName) => {
              clearByName('serviceStationsIds');
            },
          },
          month: {
            name: 'month',
            label: this.translator.tr('filters.kpis_month'),
            type: filterTypes.RADIO,
            options: this.months,
          },
          year: {
            name: 'year',
            label: this.translator.tr('filters.kpis_year'),
            type: filterTypes.RADIO,
            options: this.years,
          },
          type: {
            name: 'type',
            label: this.translator.tr('filters.displayType'),
            type: filterTypes.RADIO,
            options: [
              { Id: displayType.MONTH, Name: this.translator.tr('general.month').toLowerCase() },
              { Id: displayType.WEEK, Name: this.translator.tr('general.week').toLowerCase() },
            ],
          },
        };
        res(filters);
      });
    });
  }

  protected getFilterKey() {
    return 'SERVICE_WEEK_REPORT_FILTER';
  }

  async activate() {
    // Load values from last visit
    this.loadDateValues();

    await this.getServiceStations();

    // void it to prevent it from blocking navigation, as this is expensive.
    void this.getWeekReport();
  }

  protected setCountry(country: string) {
    let stationsToShow: Models.ServiceStation[] = [];
    if (country === 'all') {
      stationsToShow = this.serviceStations;
    } else {
      stationsToShow = this.serviceStations.filter((st) => st.Country?.toUpperCase() === country.toUpperCase());
    }
    this.filterClearSingle('serviceStationsIds');
    this.reports.clear();
    for (const s of stationsToShow) {
      this.setFilterValueByName('serviceStationsIds', { Id: s.Id });
    }
    void this.getWeekReport();
  }

  async getServiceStations() {
    const serviceStations = await this.serviceStationService.getAllCached();
    this.countries = Array.from(
      new Set(
        serviceStations
          .filter((st) => !!st.Country)
          .map((st) => {
            let country = st.Country?.toLowerCase().trim();
            const end = country.slice(1);
            country = country[0].toUpperCase() + end;
            return country;
          })
      )
    );
    this.serviceStations = serviceStations.filter((x) => !x.IsDeleted);
  }

  setFirstWeekForYearAndMonth(year: number, month: number) {
    this.week = moment.default(`${year}-${month}-01`).isoWeek();
    // If the january 1st is in previous year, set the week to 1 instead of 52
    if (month == 1 && this.week > 50) {
      this.week = 1;
    }
  }

  setYear(year: number) {
    if (this.year != +year) {
      this.year = +year;
      this.setFirstWeekForYearAndMonth(this.year, this.month);
    }
  }

  setMonth(month: number) {
    if (this.month != +month) {
      this.month = +month;
      this.setFirstWeekForYearAndMonth(this.year, this.month);
    }
  }

  nextWeek() {
    const weekDate = moment.default(`${this.year}-01-01`).add(this.week, 'week').add(1, 'week');
    this.week = weekDate.isoWeek();
    this.year = weekDate.year();
    this.month = weekDate.month() + 1;
    this.setFilterValuesDates();
    void this.getWeekReport();
  }

  nextMonth() {
    const weekDate = moment.default(`${this.year}-01-01`).add(this.month, 'month').startOf('month');
    // const currentMonthFirstDayISOWeek = weekDate.startOf('month').isoWeek();
    // const currentMonthLastDayISOWeek = weekDate.endOf('month').isoWeek();
    if (this.type == displayType.WEEK) {
      this.startOnFirstWeekOfMonth = true;
    }

    this.week = weekDate.isoWeek();
    this.year = weekDate.year();
    this.month = weekDate.month() + 1;
    this.setFilterValuesDates();
    void this.getWeekReport();
  }

  prevWeek() {
    const weekDate = moment.default(`${this.year}-01-01`).add(this.week - 1, 'week');

    if (weekDate.isoWeek() == 52 && this.week == 1) {
      this.week = weekDate.isoWeek();
      this.year = weekDate.year() - 1;
      this.month = 12;
    } else {
      this.week = weekDate.isoWeek();
      this.year = weekDate.year();
      this.month = weekDate.month() + 1;
    }
    this.setFilterValuesDates();
    void this.getWeekReport();
  }

  prevMonth() {
    const weekDate = moment
      .default(`${this.year}-01-01`)
      .add(this.month - 2, 'month')
      .startOf('month');

    if (this.type == displayType.WEEK) {
      this.startOnFirstWeekOfMonth = true;
    }

    this.week = weekDate.isoWeek();
    this.year = weekDate.year();
    this.month = weekDate.month() + 1;
    this.setFilterValuesDates();
    void this.getWeekReport();
  }

  setFilterValuesDates() {
    this.setFilterValueByName('month', { Id: this.month });
    this.setFilterValueByName('year', { Id: this.year });
  }

  getFilterValues() {
    return GetCallbackData(this.filterGetFiltersQuery);
  }

  showServiceStation(s: string) {
    const sw = this.reports.get(s);
    for (const qw of Array.from(sw.values())) {
      for (const w of Array.from(qw.values())) {
        w.show = !w.show;
      }
    }
  }

  private totals = {
    wash: 0,
    waste: 0,
    repair: 0,
    antifouling: 0,
    total: 0,
  };

  private sums = new Map<
    string,
    {
      wash: number;
      waste: number;
      repair: number;
      antifouling: number;
      total: number;
      week?: string;
    }
  >();

  async getWeekReport(filterChanged?: boolean) {
    try {
      const filter = await this.getFilterValues();

      // If there are no filters, or missing a property we reset selections, and return early
      if (!filter.serviceStationsIds?.length || !filter.month || !filter.year) {
        this.reports = new Map();
        return;
      }

      const { year, month, serviceStationsIds, type } = filter;

      // Update view/state with new values
      if (filterChanged) {
        this.setMonth(month);
        this.setYear(year);
      }
      this.type = type;

      let weeks: number[] = [];
      let currentMonth: undefined | number = undefined;
      let currentYear = year;
      if (type && type == displayType.MONTH) {
        const date = moment.default(`${year}-${month}-03`);
        const firstWeek = date.startOf('month').isoWeek();
        const lastWeek = date.endOf('month').isoWeek();
        currentMonth = month;
        currentYear = date.startOf('month').year();
        if (firstWeek >= 52) {
          weeks = new Array(lastWeek).fill(null).map((_, i) => i + 1);
          weeks.unshift(firstWeek);
        } else {
          weeks = new Array(lastWeek - (firstWeek - 1)).fill(null).map((_, i) => firstWeek + i);
        }
      } else {
        const date = moment.default(`${year}-01-01`).add(this.week, 'week');
        const currentWeek = date.isoWeek();
        const altWeek2 = date.add(1, 'weeks').isoWeek();
        let altWeek = date.add(-2, 'weeks').startOf('week').isoWeek();
        if (this.startOnFirstWeekOfMonth) {
          altWeek = date.add(3, 'weeks').isoWeek();
          weeks = [currentWeek, altWeek2, altWeek];
          this.week = altWeek2;
        } else {
          weeks = [altWeek, currentWeek, altWeek2];
        }
        currentYear = date.year();
      }

      this.startOnFirstWeekOfMonth = false;

      // Remove service stations from view, if they are not selected any more.
      if (serviceStationsIds?.length < this.reports.size) {
        // Removes repors that is not part of the selected values.
        Array.from(this.reports.keys()).filter((stationName) => {
          const station = this.serviceStations.find((station) => station.Name === stationName);
          // const se = this.serviceStations.find(ss=> ss.Id ==)
          const notInResult = !serviceStationsIds.some((stationId: number) => +stationId === +station.Id);
          if (notInResult) this.reports.delete(stationName);
        });
        return;
      }

      this.isLoading = true;
      const monthReportData = await this.serviceReportService.getServiceWeekReport(
        serviceStationsIds,
        currentYear,
        weeks.join(','),
        currentMonth,
        false
      );
      // const monthReportData = [];

      this.sums = new Map();
      this.totals = { antifouling: 0, wash: 0, total: 0, waste: 0, repair: 0 };

      if (!monthReportData || !monthReportData.length) {
        this.reports = new Map();
        this.isLoading = false;
        return;
      }

      const months: Map<string, Set<number>> = new Map();
      this.monthWeeks = new Map();

      for (const weekReport of monthReportData) {
        const serviceStationMonths = new Map();
        const serviceStationReport = weekReport;

        for (const serviceWeek of serviceStationReport.Weeks) {
          const weekFromDate = new Date(serviceWeek.FromDate);
          const year = weekFromDate.getFullYear();
          const month = weekFromDate.toLocaleString(this.translator.getLocale(), { month: 'long' }) + ` ${year}`;
          const monthShort = weekFromDate.toLocaleString(this.translator.getLocale(), { month: 'short' });
          const week = moment.default(weekFromDate).isoWeek().toString();

          const m = months.get(month);
          if (!m) {
            months.set(month, new Set([+week]));
          } else m.add(+week);

          if (!serviceStationMonths.has(month)) serviceStationMonths.set(month, new Map());

          const serviceMonths = serviceStationMonths.get(month);
          if (!serviceMonths.has(week)) serviceMonths.set(week, serviceWeek);

          // Calculate totals
          const monthWeek = `${week} (${monthShort})`;
          let weekData = this.sums.get(monthWeek);
          if (!weekData) {
            weekData = { week, wash: 0, waste: 0, repair: 0, antifouling: 0, total: 0 };
            this.sums.set(monthWeek, weekData);
          }

          weekData.wash += serviceWeek.SumEstimateWashing;
          weekData.waste += serviceWeek.SumEstimateWaste;
          weekData.repair += serviceWeek.SumEstimateRepair;
          weekData.antifouling += serviceWeek.SumEstimateAntifouling;
          weekData.total += serviceWeek.SumTotal;

          this.totals.wash += serviceWeek.SumEstimateWashing;
          this.totals.waste += serviceWeek.SumEstimateWaste;
          this.totals.repair += serviceWeek.SumEstimateRepair;
          this.totals.antifouling += serviceWeek.SumEstimateAntifouling;
          this.totals.total += serviceWeek.SumTotal;
        }

        this.reports.set(serviceStationReport.ServiceStationName, serviceStationMonths);
      }

      for (const [k, v] of Array.from(months.entries())) {
        this.monthWeeks.set(k, Array.from(v));
      }

      this.isLoading = false;

      setTimeout(() => {
        tippy('.tooltip-item', {
          allowHTML: true,
          maxWidth: '300px',
          hideOnClick: true,
          content: (reference) => {
            const title = reference.getAttribute('title');
            reference.removeAttribute('title');
            return title;
          },
        });
      });

      this.saveDateValues();
    } catch (error) {
      this.isLoading = false;
      this.errorService.handleError(error);
    }
  }

  saveDateValues() {
    const data = { week: this.week, month: this.month, year: this.year };
    window.localStorage.setItem(WEEK_REPORT_DATE_VALUES, JSON.stringify(data));
  }

  loadDateValues() {
    const data = window.localStorage.getItem(WEEK_REPORT_DATE_VALUES);
    if (!data) return;

    const dates = JSON.parse(data);
    if (dates.week && dates.month && dates.year) {
      this.year = +dates.year;
      this.month = +dates.month;
      this.week = +dates.week;
    }
  }

  showDetails(serviceStation: string, type: string, date: Date, netTypeId?: number, specialProductTypeId?: number) {
    const detailsDate = moment.default(date);

    const serviceStationId = this.serviceStations.find((s) => s.Name == serviceStation)?.Id;

    const params = {
      serviceStationId: serviceStationId,
      year: detailsDate.year(),
      month: detailsDate.month() + 1,
      week: detailsDate.week(),
      type: type,
      netTypeId: netTypeId,
      specialProductTypeId: specialProductTypeId,
    };

    // show dialog with details
    this.dialogService
      .open({
        viewModel: WeekReportDetailsDialog,
        model: params,
        lock: false,
        position: () => {
          /* needs to be here */
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }

  getAntifoulingMissingPricesText(missingTextString: string) {
    const types = new Set(missingTextString.split(',').map((v) => v.trim()));
    return this.translator.tr('general.missingPrices') + ': ' + Array.from(types).join(',');
  }
}
